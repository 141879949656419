import { SHAPES } from "./svg";
import { createColorStyle } from "../utils/style";
import { IEditorConfig, IScale } from "../types/editor";
import {
  UNIT_FEET,
  QUANTITIES,
  QUANTITY_AREA,
  QUANTITY_COUNT,
  QUANTITY_LENGTH,
} from "./classifications";

export const DEFAULT_ML_THRESHOLD = 0.7;
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// COLOR CONSTS
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

export const OVERLAY_TYPES = ["t1", "t2", "t3"];
export const OVERLAY_COLORS = {
  [OVERLAY_TYPES[0]]: "#F04F23",
  [OVERLAY_TYPES[1]]: "#2F80ED",
};

export const DEFAULT_SHAPE = SHAPES.SQUARE;
export const DEFAULT_COLOR = createColorStyle([61, 184, 66], "#3DB842");
export const UNASSIGNED_COLOR = createColorStyle([140, 140, 140], "#1C2931");
export const DEFAULT_COLOR_DARK = createColorStyle([18, 130, 76], "#12824C");

export const CLASSIFICATIONS_CHANGE_TYPES = {
  add: "add",
  duplicateCl: "duplicateCl",
  duplicateGroup: "duplicateGroup",
  move: "move",
  local: "local",
  update: "update",
  delete: "delete",
  ungroup: "ungroup",
  addGroup: "addGroup",
  addSelected: "addSelected",
  addDefaults: "addDefaults",
  updateGroup: "updateGroup",
  deleteGroup: "deleteGroup",
  updateMultiple: "updateMultiple",
  deleteMultiple: "deleteMultiple",
};

export const CLS_COLORS = [
  DEFAULT_COLOR,
  DEFAULT_COLOR_DARK,
  createColorStyle([247, 21, 184], "#F715B8"),
  createColorStyle([221, 72, 255], "#DE48FF"),
  createColorStyle([255, 90, 218], "#FF5ADA"),
  createColorStyle([137, 83, 255], "#8953FF"),
  createColorStyle([58, 134, 255], "#3A84FF"),
  createColorStyle([74, 237, 221], "#4AEDDD"),
  createColorStyle([56, 246, 132], "#38F683"),
  createColorStyle([233, 237, 14], "#E8ED0E"),
  createColorStyle([255, 215, 6], "#FFD706"),
  createColorStyle([255, 98, 7], "#FF6107"),
  createColorStyle([223, 0, 0], "#DF0000"),
  createColorStyle([247, 21, 96], "#F71560"),
  createColorStyle([148, 13, 178], "#940DB2"),
  createColorStyle([158, 87, 141], "#9E578E"),
  createColorStyle([95, 63, 164], "#5F3FA4"),
  createColorStyle([107, 150, 225], "#6B97E1"),
  createColorStyle([95, 214, 202], "#5FD6CA"),
  createColorStyle([0, 232, 190], "#00E8BE"),
  createColorStyle([193, 196, 39], "#C1C427"),
  createColorStyle([253, 184, 47], "#FDB82F"),
  createColorStyle([253, 123, 47], "#FD792F"),
  createColorStyle([253, 47, 47], "#FD2F2F"),
  createColorStyle([232, 166, 188], "#E8A6BC"),
  createColorStyle([180, 143, 188], "#B48FBC"),
  createColorStyle([153, 153, 153], "#999999"),
  createColorStyle([104, 91, 133], "#685B85"),
  createColorStyle([81, 91, 108], "#515B6C"),
  createColorStyle([171, 208, 204], "#ABD0CC"),
  createColorStyle([171, 220, 190], "#ABDCBE"),
  createColorStyle([216, 217, 194], "#D8D9C2"),
  createColorStyle([214, 128, 0], "#D68000"),
  createColorStyle([222, 177, 152], "#DEB198"),
  createColorStyle([203, 168, 168], "#CBA8A8"),
];

export const MARKUP_COLORS = [
  createColorStyle([235, 87, 87], "#EB5757"),
  createColorStyle([242, 152, 74], "#F2994A"),
  createColorStyle([242, 200, 76], "#F2C94C"),
  createColorStyle([39, 174, 95], "#27AE60"),
  createColorStyle([45, 155, 219], "#2D9CDB"),
  createColorStyle([155, 81, 224], "#9B51E0"),
  createColorStyle([51, 51, 51], "#333333"),
  createColorStyle([189, 189, 189], "#BDBDBD"),
  createColorStyle([247, 247, 247], "#F7F7F7", 0),
];

export const DEFAULT_MARKUP_COLOR = MARKUP_COLORS[0];
export const DEFAULT_MARKUP_TEXT_COLOR = MARKUP_COLORS[6];
export const DEFAULT_MARKUP_TRANSPARENT = MARKUP_COLORS[8];

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// EDITOR CONST
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

export const UNITS = {
  si: "si",
  metric: "metric",
  imperial: "imperial",
};

export const DEFAULT_CONFIG: IEditorConfig = {
  lineWidth: 2,
  pointSize: 10,
  imageOpacity: 1,
  snapDistance: 5,
  imageBrightness: 1,
  wallsThikness: 0.3,
  polygonOpacity: 0.7,

  isSnapOn: false,
  showLegend: true,
  showMarkup: true,
  useMetrics: false,
  isCrosshairOn: true,
  isRotateImage: false,
  isImageColored: true,
  automaticSnapping: true,

  scale_units: UNITS.imperial,
};

export const EVENTS: Record<string, string> = {
  TOAST_ERROR: "TOAST_ERROR",
  TOAST_INFO: "TOAST_INFO",
  TOAST_SUCCESS: "TOAST_SUCCESS",
  CREATE_LINE: "CREATE_LINE",
  CREATE_POLYGON: "CREATE_POLYGON",
  CONTEXTMENU: "CONTEXTMENU",
};

const architectural = (label: string, value: [number, number]): IScale => ({
  type: "architectural",
  unit: "inches",
  label,
  value,
});
const metric = (label: string, value: [number, number]): IScale => ({
  type: "metric",
  unit: "px",
  label,
  value,
});
const civil = (label: string, value: [number, number]): IScale => ({
  type: "civil",
  unit: "inches",
  label,
  value,
});

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// EDITOR SCALES CONST
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

export const SCALES_TYPE = {
  ARCHITECTURAL: "architectural",
  METRIC: "metric",
  CIVIL: "civil",
};

export const SCALES_BY_TYPE: Record<string, IScale[]> = {
  architectural: [
    architectural('3/64" = 1\' 0"', [3 / 64, 12]),
    architectural('1/32" = 1\' 0"', [1 / 32, 12]),
    architectural('1/16" = 1\' 0"', [1 / 16, 12]),
    architectural('3/32" = 1\' 0"', [3 / 32, 12]),
    architectural('1/8" = 1\' 0"', [1 / 8, 12]),
    architectural('3/16" = 1\' 0"', [3 / 16, 12]),
    architectural('1/4" = 1\' 0"', [1 / 4, 12]),
    architectural('3/8" = 1\' 0"', [3 / 8, 12]),
    architectural('1/2" = 1\' 0"', [1 / 2, 12]),
    architectural('3/4" = 1\' 0"', [3 / 4, 12]),
    architectural('1" = 1\' 0"', [1, 12]),
    architectural('1 1/2" = 1\' 0"', [1.5, 12]),
    architectural('3" = 1\' 0"', [3, 12]),
  ],
  metric: [
    metric("1 : 1250", [1, 1250]),
    metric("1 : 1000", [1, 1000]),
    metric("1 : 500", [1, 500]),
    metric("1 : 300", [1, 300]),
    metric("1 : 250", [1, 250]),
    metric("1 : 200", [1, 200]),
    metric("1 : 125", [1, 125]),
    metric("1 : 100", [1, 100]),
    metric("1 : 60", [1, 60]),
    metric("1 : 50", [1, 50]),
    metric("1 : 20", [1, 20]),
    metric("1 : 10", [1, 10]),
    metric("1 : 5", [1, 5]),
  ],
  civil: [
    civil('1" = 1\' 0"', [1, 12]),
    civil('1" = 10\' 0"', [1, 10 * 12]),
    civil('1" = 20\' 0"', [1, 20 * 12]),
    civil('1" = 30\' 0"', [1, 30 * 12]),
    civil('1" = 40\' 0"', [1, 40 * 12]),
    civil('1" = 50\' 0"', [1, 50 * 12]),
    civil('1" = 60\' 0"', [1, 60 * 12]),
    civil('1" = 70\' 0"', [1, 70 * 12]),
    civil('1" = 80\' 0"', [1, 80 * 12]),
    civil('1" = 90\' 0"', [1, 90 * 12]),
    civil('1" = 100\' 0"', [1, 100 * 12]),
  ],
};

export const COMBINED_SCALES_TYPE: IScale[] = [
  ...SCALES_BY_TYPE[SCALES_TYPE.ARCHITECTURAL],
  ...SCALES_BY_TYPE[SCALES_TYPE.CIVIL],
  ...SCALES_BY_TYPE[SCALES_TYPE.METRIC],
];

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// TAKEOFF CONST
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export const TAKE_OFF_TYPES = [
  {
    id: "WITHOUT_BOUNDARIES",
    label: "Area without walls",
  },
  {
    id: "WITH_BOUNDARIES",
    label: "Area with walls",
  },
  {
    id: "JUST_BOUNDARIES",
    label: "Wall analysis",
  },
  {
    id: "COUNT",
    label: "Count",
  },
];

export const TAKE_OFFS = {
  WITHOUT_BOUNDARIES: 0,
  WITH_BOUNDARIES: 1,
  JUST_BOUNDARIES: 2,
  COUNT: 3,
};

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// CLASSIFICATION CONST
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

export const COMPARISON_LAYERS = {
  original: "original",
  overlay: "overlay",
  comparison: "comparison",
};

export const DEFAULT_COMPARISON_COLORS = {
  [COMPARISON_LAYERS.original]: createColorStyle([240, 79, 35], "#F04F23"),
  [COMPARISON_LAYERS.overlay]: createColorStyle([47, 128, 237], "#2F80ED"),
};

export const HOLE_TYPE = "hole";
export const CLASSIFICATION_TYPES = ["area", "line", "count"] as const;
export const CLASSIFICATION_CATEGORIES: string[] = ["cls", "group", "folder"];
export const FILTERS: Record<string, string> = {
  WITHOUT_BOUNDARIES: CLASSIFICATION_TYPES[0],
  WITH_BOUNDARIES: CLASSIFICATION_TYPES[0],
  JUST_BOUNDARIES: CLASSIFICATION_TYPES[1],
  COUNT: CLASSIFICATION_TYPES[2],
};

export const GEOJSON_FILTERS: Record<string, string> = {
  Polygon: CLASSIFICATION_TYPES[0],
  MultiPolygon: CLASSIFICATION_TYPES[0],
  LineString: CLASSIFICATION_TYPES[1],
  MultiLineString: CLASSIFICATION_TYPES[1],
  Point: CLASSIFICATION_TYPES[2],
  MultiPoint: CLASSIFICATION_TYPES[2],
};

export const DEFAULT_COUNT_ID_ML = "ML";
export const DEFAULT_ROOM_ID_ML = "room";
export const DEFAULT_REGION_ID_ML = "region";
export const DEFAULT_WALLS_ID_ML = "centerline";

export const DEFAULT_AREA_ID = "0000";
export const DEFAULT_REGION_ID = "0001";
export const DEFAULT_LINE_ID = "0010";
export const DEFAULT_LINE_POLYGON_ID = "0011";
export const DEFAULT_COUNT_ID = "0100";
export const DEFAULT_DOORS_ID = "0101";
export const DEFAULT_LINE_PERIMETER_ID = "0110";
export const DEFAULT_GROSS_INTERNAL_AREA_ID = "0111";
export const DEFAULT_ML_COUNT_ID = "1000";
export const DEFAULT_DOORS_LINE_ID = "1011";
export const DEFAULT_ML_AREA_ID = "10001";
export const DEFAULT_ML_WALL_ID = "10010";
export const DEFAULT_LABEL = "Unclassified";
export const DOORS_POLYGON_ID = "door_polygon";
export const DEFAULT_FOOTPRINT_ID = "footprint";
export const DEFAULT_FOLDER_ID = "default_folder";
export const DEFAULT_FOOTPRINT_LABEL = "Footprint";
export const DEFAULT_WALLS_LABEL = "Wall centerline";
export const DOORS_CENTERLINE_ID = "door_centerline";
export const DEFAULT_TEXT_FEATURES_ID = "text_features";
export const DEFAULT_FOLDER_OCR_ID = "default_folder_ocr";
export const DEFAULT_WALL_PERIMETER_TYPE = "wall_perimeter";
export const DEFAULT_FOLDER_AREA_ID = "default_folder_area";
export const DEFAULT_FOLDER_LINE_ID = "default_folder_line";
export const DEFAULT_FOLDER_COUNT_ID = "default_folder_count";
export const DEFAULT_SEARCH_RESULT_ID_TYPES = "SEARCH_RESULT";
export const DEFAULT_UNASSIGNED_FEATURES_ID = "unassigned_features";
export const DEFAULT_FOLDER_LINE_CLS_ID = "default_folder_line_CLS";

export const DEFAULT_FOLDERS_IDS: string[] = [
  DEFAULT_FOLDER_ID,
  DEFAULT_FOLDER_OCR_ID,
  DEFAULT_FOLDER_AREA_ID,
  DEFAULT_FOLDER_LINE_ID,
  DEFAULT_FOLDER_COUNT_ID,
  DEFAULT_FOLDER_LINE_CLS_ID,
];
export const DEFAULT_FOLDERS_IDS_SET = new Set(DEFAULT_FOLDERS_IDS);

export const DEFAULT_ML_COUNT_LABELS = [
  "Bathtub",
  "Chair",
  "Cooktop",
  "Dining table",
  "Double Bed",
  "Double Swing Door",
  "Dryer",
  "Gym Equipment",
  "Living Table",
  "Office Chair",
  "Office Desk",
  "Office Table",
  "Parking Lot",
  "Shower",
  "Amenities",
  "Single Bed",
  "Single Swing Door",
  "Sink",
  "Sliding Door",
  "Small table",
  "Sofa Multi",
  "Sofa Single",
  "Toilet",
  "Tv/Monitor",
  "Urinals",
  "Water Fountains",
  "Exit sign",
  "Shower Door",
];

export const DEFAULT_ML_AREA_LABELS = [
  "Amenitites",
  "Balcony",
  "Bathroom",
  "Bedroom",
  "Classroom",
  "Closet",
  "Corridor",
  "Elevator",
  "Hotel Room",
  "Kitchen",
  "Living Area",
  "Living Room",
  "Lobby Elevator",
  "Office",
  "Openings",
  "Parking Lot",
  "Amenities",
  "Public Bathroom",
  "Retail",
  "Roof",
  "Shafts",
  "Stairs",
  "Utility",
];

export const DEFAULT_ML_WALL_LABELS = [
  { label: "Bathroom", color: CLS_COLORS[21] },
  { label: "Corridor", color: CLS_COLORS[5] },
  { label: "Demising", color: CLS_COLORS[6] },
  { label: "Exterior", color: CLS_COLORS[1] },
  { label: "Interior", color: CLS_COLORS[4] },
  { label: "Isolated", color: CLS_COLORS[9] },
  { label: "Kitchen", color: CLS_COLORS[10] },
  { label: "Shaft", color: CLS_COLORS[14] },
];

export const DEFAULT_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal Classifications",
  id: DEFAULT_FOLDER_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_AREA_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal areas",
  id: DEFAULT_FOLDER_AREA_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_OCR_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal OCR",
  id: DEFAULT_FOLDER_OCR_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_LINEAR_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal lines",
  id: DEFAULT_FOLDER_LINE_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_COUNT_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal counts",
  id: DEFAULT_FOLDER_COUNT_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_WALLS_CLASSIFICATIONS_FOLDER: any = {
  category: CLASSIFICATION_CATEGORIES[2],
  type: CLASSIFICATION_TYPES[0],
  label: "Togal wall classifications",
  id: DEFAULT_FOLDER_LINE_CLS_ID,
  colorStyle: DEFAULT_COLOR_DARK,
  children: [],
  shape: DEFAULT_SHAPE,
  height: 0,
  visible: true,
  isStatic: true,
};

export const DEFAULT_FOLDERS_MAP = {
  [DEFAULT_FOLDER_ID]: DEFAULT_FOLDER,
  [DEFAULT_FOLDER_OCR_ID]: DEFAULT_OCR_FOLDER,
  [DEFAULT_FOLDER_AREA_ID]: DEFAULT_AREA_FOLDER,
  [DEFAULT_FOLDER_LINE_ID]: DEFAULT_LINEAR_FOLDER,
  [DEFAULT_FOLDER_COUNT_ID]: DEFAULT_COUNT_FOLDER,
  [DEFAULT_FOLDER_LINE_CLS_ID]: DEFAULT_WALLS_CLASSIFICATIONS_FOLDER,
};

export const DEFAULT_CLASSIFICATION: any[] = [
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: DEFAULT_FOOTPRINT_LABEL,
    id: DEFAULT_FOOTPRINT_ID,
    colorStyle: CLS_COLORS[2],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: "Gross Internal Area (GIA)",
    id: DEFAULT_GROSS_INTERNAL_AREA_ID,
    colorStyle: DEFAULT_COLOR_DARK,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: "Gross area",
    id: DEFAULT_AREA_ID,
    colorStyle: DEFAULT_COLOR_DARK,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: "Net area",
    id: DEFAULT_REGION_ID,
    colorStyle: DEFAULT_COLOR,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: "Doors area",
    id: DEFAULT_DOORS_ID,
    colorStyle: CLS_COLORS[10],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 70,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label: "Wall area",
    id: DEFAULT_LINE_POLYGON_ID,
    colorStyle: CLS_COLORS[7],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_LINE_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[1],
    label: DEFAULT_WALLS_LABEL,
    id: DEFAULT_LINE_ID,
    colorStyle: DEFAULT_COLOR,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_LINE_ID,

    quantity1: QUANTITY_LENGTH,
    quantity1UOM: QUANTITIES[QUANTITY_LENGTH].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 4,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[1],
    label: "Wall Perimeter",
    id: DEFAULT_LINE_PERIMETER_ID,
    colorStyle: CLS_COLORS[12],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_LINE_ID,

    quantity1: QUANTITY_LENGTH,
    quantity1UOM: QUANTITIES[QUANTITY_LENGTH].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 4,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[1],
    label: "Doors centerline",
    id: DEFAULT_DOORS_LINE_ID,
    colorStyle: CLS_COLORS[16],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_LINE_ID,

    quantity1: QUANTITY_LENGTH,
    quantity1UOM: QUANTITIES[QUANTITY_LENGTH].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 4,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  {
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[2],
    label: DEFAULT_LABEL,
    id: DEFAULT_COUNT_ID,
    colorStyle: DEFAULT_COLOR,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_COUNT_ID,

    quantity1: QUANTITY_COUNT,
    quantity1UOM: QUANTITIES[QUANTITY_COUNT].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 12,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  },
  ...DEFAULT_ML_AREA_LABELS.map((label, i) => ({
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[0],
    label,
    id: "area_" + label,
    colorStyle: CLS_COLORS[CLS_COLORS.length - (i + 1)],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_AREA_ID,

    quantity1: QUANTITY_AREA,
    quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    offset: 0,
    height: 100,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  })),
  ...DEFAULT_ML_COUNT_LABELS.map((label, i) => ({
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[2],
    label,
    id: label,
    colorStyle: CLS_COLORS[i],
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_COUNT_ID,

    quantity1: QUANTITY_COUNT,
    quantity1UOM: QUANTITIES[QUANTITY_COUNT].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 12,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 0,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  })),
  ...DEFAULT_ML_WALL_LABELS.map(({ label, color }) => ({
    category: CLASSIFICATION_CATEGORIES[0],
    type: CLASSIFICATION_TYPES[1],
    label,
    id: "wall_" + label,
    colorStyle: color,
    children: [],
    shape: DEFAULT_SHAPE,
    visible: false,
    isStatic: true,
    folderId: DEFAULT_FOLDER_LINE_ID,

    quantity1: QUANTITY_LENGTH,
    quantity1UOM: QUANTITIES[QUANTITY_LENGTH].defaultUnit.id,
    quantity2: null,
    quantity2UOM: null,
    quantity3: null,
    quantity3UOM: null,
    quantity4: null,
    quantity4UOM: null,

    width: 0,
    length: 0,
    height: 0,
    offset: 0,
    thikness: 4,
    inputGridUOM: UNIT_FEET,
    inputWidthUOM: UNIT_FEET,
    inputLengthUOM: UNIT_FEET,
    inputHeightUOM: UNIT_FEET,
    inputOffsetUOM: UNIT_FEET,
    inputThicknessUOM: UNIT_FEET,
  })),
];

export const DEFAULT_CLASSIFICATION_IDS = DEFAULT_CLASSIFICATION.map(
  (el) => el.id
);
export const DEFAULT_CLASSIFICATION_IDS_SET = new Set(
  DEFAULT_CLASSIFICATION_IDS
);

export const DEFAULT_CLASSIFICATION_MAP: Record<string, any> =
  DEFAULT_CLASSIFICATION.reduce((acc, el) => ({ ...acc, [el.id]: el }), {});

export const MANUAL_DATA = "MANUAL";

export interface IGeojsonTypes {
  Point: "Point";
  Polygon: "Polygon";
  Feature: "Feature";
  LineString: "LineString";
  MultiPoint: "MultiPoint";
  MultiPolygon: "MultiPolygon";
  MultiLineString: "MultiLineString";
  FeatureCollection: "FeatureCollection";
  legend: "legend";
  hole: "hole";
  markup: "markup";
  breakdown: "breakdown";
  markupGroup: "Markup Items";
  dimensionLine: "dimensionLine";
  RoomGIA: "room_gia";
}

export const GEOJSON_TYPES: IGeojsonTypes = {
  Point: "Point",
  Polygon: "Polygon",
  Feature: "Feature",
  LineString: "LineString",
  MultiPoint: "MultiPoint",
  MultiPolygon: "MultiPolygon",
  MultiLineString: "MultiLineString",
  FeatureCollection: "FeatureCollection",

  legend: "legend",
  hole: "hole",
  markup: "markup",
  breakdown: "breakdown",
  markupGroup: "Markup Items",
  dimensionLine: "dimensionLine",
  RoomGIA: "room_gia",
};

export const GEOJSON_TYPES_CLASSIFICATIONS: any = {
  [GEOJSON_TYPES.Point]: DEFAULT_COUNT_ID,
  [GEOJSON_TYPES.MultiPoint]: DEFAULT_COUNT_ID,

  [GEOJSON_TYPES.Polygon]: DEFAULT_REGION_ID,
  [GEOJSON_TYPES.MultiPolygon]: DEFAULT_REGION_ID,

  [GEOJSON_TYPES.LineString]: DEFAULT_LINE_ID,
  [GEOJSON_TYPES.MultiLineString]: DEFAULT_LINE_ID,
};

export const GEOMETRY_TYPES = [
  GEOJSON_TYPES.Point,
  GEOJSON_TYPES.MultiPoint,
  GEOJSON_TYPES.Polygon,
  GEOJSON_TYPES.MultiPolygon,
  GEOJSON_TYPES.LineString,
  GEOJSON_TYPES.MultiLineString,
];

export const COORDINATE_NEST_LEVEL: Record<string, number> = {
  Point: 1,
  MultiPoint: 2,
  LineString: 2,
  MultiLineString: 3,
  Polygon: 3,
  MultiPolygon: 4,
};

export interface IFilter {
  type: number;
  key: string;
  value: string[] | number;
  func?: (value: string, key: string, groupMap: any) => boolean;
}

export const DEFAULT_TAKEOFFS_FILTER: IFilter = {
  type: 4,
  key: "properties.types",
  value: TAKE_OFF_TYPES.map((el) => el.id),
};

export const DEFAULT_SEARCH_FILTER: IFilter = {
  type: 3,
  key: "properties.name",
  value: null,
};

export const CLASSIFICATION_SEARCH_FILTER: IFilter = {
  type: 5,
  key: "properties.className",
  value: null,
  func: (value, key, groupMap) => {
    return groupMap[key]?.label.toLowerCase().includes(value.toLowerCase());
  },
};

export const DEFAULT_GROUP: IFilter = {
  type: 0,
  key: "properties.className",
  value: null,
};

export const AREA_GROUP: IFilter = {
  type: 1,
  key: "properties.area",
  value: 50,
};

export const PERIM_GROUP: IFilter = {
  type: 1,
  key: "properties.perimeter",
  value: 10,
};

export const TYPES_GROUP: IFilter = {
  type: 2,
  key: "properties.types",
  value: TAKE_OFF_TYPES.map((el) => el.id),
};

export const HIGHLIGHT_COLOR = [120, 120, 120, 80];
export const TYPES_GROUP_COLORS = [11, 17, 12, 7];
export const GREYED_OUT_COLOR = [160, 160, 160];
export const COASTAL_ORG_IDS: string[] = [
  "9784b03f-a53f-4b48-8574-43e91c180b13",
  "9a90a1c1-eca5-4f0c-9cc3-ca615f13d3e3",
  "2394117f-61e2-4ce8-acf6-057581e891f3",
  "e4143bb6-e097-4acd-8322-0ef542de8b16",
];

export const ARC_EDIT_HANDLE = "arc";
export const SNAP_EDIT_HANDLE = "snap";
export const EDIT_HANDLE_HOVERED = "hovered";
export const ARC_CONTROL_EDIT_HANDLE = "arc-control";
export const INTERMEDIATE_EDIT_HANDLE = "intermediate";

export const MODES_IDS = {
  search: "search",
  pointer: "pointer",
  point: "point",
  pan: "pan",
  polygon: "polygon",
  polygon90: "polygon90",
  rect: "rect",
  split: "split",
  hole: "hole",
  merge: "merge",
  line: "line",
  pipeline: "pipeline",
  lineMerge: "lineMerge",
  lineSplit: "lineSplit",
  magic_tool: "magic_tool",
  dimensionLine: "dimensionLine",
  markup_text_box: "markup_text_box",
  markup_text: "markup_text",
  markup_text_arrow: "markup_text_arrow",
  markup_box: "markup_box",
  markup_border: "markup_border",
  markup_thickness: "markup_thickness",
  markup_fill: "markup_fill",
  markup_arrow: "markup_arrow",
  markup_line: "markup_line",
  history: "history",
  markup_rounded_box: "markup_rounded_box",
  markup_ellipse: "markup_ellipse",
  markup_polygon: "markup_polygon",
  markup_cloud: "markup_cloud",
  markup_freehand: "markup_freehand",
  comparison_pointer: "comparison_pointer",
  comparison_align: "comparison_align",
  comparison_dimensionLine: "comparison_dimensionLine",
  markup_text_bold: "markup_text_bold",
  markup_text_italic: "markup_text_italic",
  markup_text_underline: "markup_text_underline",
  markup_align_left: "markup_align_left",
  markup_align_center: "markup_align_center",
  markup_align_right: "markup_align_right",
  markup_align_justify: "markup_align_justify",
};

export const DEFAULT_MARKUP_FONT_SIZE = 16;
