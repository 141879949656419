import * as setApi from "src/lib/api/set";
import * as pageApi from "src/lib/api/page";
import * as viewApi from "src/lib/api/view";
import * as exportApi from "src/lib/api/export";
import * as projectApi from "src/lib/api/project";
import * as symbolsApi from "src/lib/api/symbols";
import * as breakdownsApi from "src/lib/api/breakdowns";
import * as organizationApi from "src/lib/api/organization";
import * as classificationsFoldersApi from "src/lib/api/folders";
import * as classificationsApi from "src/lib/api/classifications";

import { HOLE_TYPE } from "sf/consts/editor";
import { COMBINED_VIEW } from "sf/permissions";

import {
  PAGES_ATTRIBUTES,
  VIEWS_ATTRIBUTES,
} from "src/UtilComponents/DataManager/const";
import config from "src/config";
import { DEFAULT_API_LIMIT } from "src/lib/utils";
import { flattenBreakdowns } from "./BreakdownPanel/utils";
import { getClsFromApi, getFolderFromApi } from "../LibraryMain/utils";
import { onThumbnailEvent } from "src/Events";

/**
 * Used for keeping track of number of atomic updates and take snapshot if too many non saved updates
 */
let totalAtomicUpdates = 0;
let lastAtomicUpdateSnapshotSync = 0;

export async function exportPdf(pageId, clMap) {
  const { body } = await exportApi.exportPdf(pageId, clMap);
  return body;
}

export async function exportPdfMultipage(
  set_id,
  clMap,
  pageIds,
  hidden,
  original,
  takeOffFilter,
  settings,
  selectedViewIds,
  combinedViewFeatures
) {
  const { body } = await exportApi.exportPdfMultipage(
    set_id,
    clMap,
    pageIds,
    hidden,
    original,
    takeOffFilter,
    settings,
    selectedViewIds,
    combinedViewFeatures
  );
  return body;
}

export async function getExportPdfByPageId(pageId) {
  const { body } = await exportApi.getExportPdfByPageId(pageId);
  return body;
}

export async function fetchEditorSet(setId) {
  const set = await setApi.get({ id: setId });
  const sets = await setApi.listAll({
    $limit: config.api.backendRequestLimit,
    $where: {
      organization_id: set.organization_id,
      project_id: set.project_id,
    },
  });

  return {
    ...set,
    sets: sets.rows,
  };
}

export async function fetchEditorProject(id) {
  const project = await projectApi.get({ id });
  return project;
}

export async function fetchSymbolsHistory(id) {
  const history = await symbolsApi.fetchSymbolsHistory({ id });
  return history;
}

export async function getSetById(setId) {
  return await setApi.get({ id: setId });
}

export async function getPageViews(pageId, setId) {
  return await viewApi.listAll({
    $offset: 0,
    $limit: config.api.backendRequestLimit,
    $attributes: VIEWS_ATTRIBUTES,
    $where: {
      page_id: pageId,
      set_id: setId,
    },
  });
}

export async function getSpecifiPageView(pageId, setId, viewName) {
  return await viewApi.listAll({
    $offset: 0,
    $limit: config.api.backendRequestLimit,
    $attributes: VIEWS_ATTRIBUTES,
    $where: {
      page_id: pageId,
      set_id: setId,
      name: viewName,
    },
  });
}

export async function createView(body) {
  return await viewApi.create(body);
}

export async function getViewReq(id) {
  return await viewApi.get({
    id,
    query: {
      $attributes: VIEWS_ATTRIBUTES,
    },
  });
}

export async function getOrg(id) {
  return await organizationApi.get({ id });
}

export async function saveViewMetadata(id, metadata) {
  return await viewApi.update({
    id,
    metadata,
  });
}

export async function savePageMetadata(id, metadata) {
  return await pageApi.update({
    id,
    metadata,
  });
}

export async function saveViewClassificationOrder(id, classification_order) {
  return await setApi.setClassificationsOrder({
    id,
    classification_order,
  });
}

export async function savePageScale(view) {
  await pageApi.update({
    id: view.page.id,
    scale_drawing: view.scale_drawing,
    scale_real: view.scale_real,
    scale_type: view.scale_type,
  });
}

export async function saveViewComparison(view, pageID, transforms) {
  const comparison_page_id = pageID || view?.comparison_page_id || "";
  try {
    await viewApi.update({
      id: view.id,
      comparison_page_id,
      comparison_translations: {
        ...view.comparison_translations,
        [comparison_page_id]: transforms,
      },
      metadata: { ...view.metadata, hasComparison: !!comparison_page_id },
    });
  } catch (e) {
    console.error("error saving comparison state : ", e);
  }
}

export async function clearViewComparisonId(view) {
  try {
    await viewApi.update({
      id: view.id,
      comparison_page_id: null,
      metadata: {
        ...view.metadata,
        hasComparison: false,
      },
    });
  } catch (e) {
    console.error("error saving comparison state : ", e);
  }
}

export async function fetchPageData(id) {
  return await pageApi.get({ id });
}

export async function listEditorPage(set_id, organization_id, id) {
  return await pageApi.list({
    $limit: DEFAULT_API_LIMIT,
    $attributes: PAGES_ATTRIBUTES,
    $where: {
      id,
      set_id,
      organization_id,
    },
  });
}

export async function getSetClassifications(
  organization_id,
  set_id,
  data = [],
  offset = 0,
  onError
) {
  let finalData = data;

  try {
    const res = await classificationsApi.listClassifications({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: { organization_id, set_id },
    });

    finalData = [...finalData, ...res.rows.map(getClsFromApi)];

    if (res.count > offset) {
      return await getSetClassifications(
        organization_id,
        set_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError
      );
    }

    return finalData;
  } catch (e) {
    onError && onError(e);
    return finalData;
  }
}

export async function getSetFolders(
  organization_id,
  set_id,
  data = [],
  offset = 0,
  onError
) {
  let finalData = data;

  try {
    const res = await classificationsFoldersApi.listFolders({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: { organization_id, set_id },
    });

    finalData = [...finalData, ...res.rows.map(getFolderFromApi)];

    if (res.count > offset) {
      return await getSetFolders(
        organization_id,
        set_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError
      );
    }

    return finalData;
  } catch (e) {
    onError && onError(e);
    return finalData;
  }
}

export async function prepareSetClassifications(
  organization_id,
  set_id,
  onError
) {
  try {
    const classifications = await getSetClassifications(
      organization_id,
      set_id,
      [],
      0,
      onError
    );

    const folders = await getSetFolders(
      organization_id,
      set_id,
      [],
      0,
      onError
    );

    return [...folders, ...classifications];
  } catch (e) {
    console.error("error : ", e);
    return null;
  }
}

export async function saveAtomicOperations(
  id,
  features,
  { addFeatures = [], editFeatures = [], deleteFeatures = [] }
) {
  if (id?.includes(COMBINED_VIEW)) return;

  const featureMap = new Map(features.map((fe) => [fe.properties.id, fe]));
  const timestamp = new Date().getTime();

  const query = [];

  for (const feature of [...addFeatures, ...editFeatures]) {
    if (!feature?.properties?.id?.includes(HOLE_TYPE)) {
      query.push({
        id: feature.properties.id,
        timestamp,
        data: feature,
      });
    } else if (featureMap.has(feature.properties.parentId)) {
      query.push({
        id: feature.properties.parentId,
        timestamp,
        data: featureMap.get(feature.properties.parentId),
      });
    }
  }

  for (const feature of deleteFeatures) {
    query.push({
      id: feature.properties.id,
      timestamp,
      data: {},
    });
  }

  totalAtomicUpdates += query?.length || 0;

  if (totalAtomicUpdates - lastAtomicUpdateSnapshotSync >= 100) {
    lastAtomicUpdateSnapshotSync = totalAtomicUpdates;
    onThumbnailEvent();
  }

  if (query.length === 0) return;

  try {
    await viewApi.atomicUpdate(id, query);
  } catch (e) {
    console.error("error : ", e);
  }
}

function getApiBreakdown(breakdown, set_id = null, organization_id = null) {
  return {
    id: breakdown.apiId,
    name: breakdown.name || "",
    parent_id: null,
    set_id,
    organization_id,
    data: {
      ...breakdown,
      children: [],
    },
  };
}

function getBreakdownFromApi(breakdown) {
  return {
    name: breakdown.name || "",
    ...breakdown.data,
    apiId: breakdown.id,
  };
}

function updateBreakdownApiId(breakdowns, breakdonwId, apiId) {
  return breakdowns.map((br) =>
    br.id === breakdonwId
      ? { ...br, apiId }
      : br?.children?.length > 0
      ? {
          ...br,
          children: updateBreakdownApiId(br.children, breakdonwId, apiId),
        }
      : br
  );
}

export async function getSetBreakdowns(
  organization_id,
  set_id,
  data = [],
  offset = 0,
  onError
) {
  let finalData = data;

  try {
    const res = await breakdownsApi.listBreakdowns({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: { organization_id, set_id },
    });

    finalData = [...finalData, ...res.rows.map(getBreakdownFromApi)];

    if (res.count > offset) {
      return await getSetBreakdowns(
        organization_id,
        set_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError
      );
    }

    return finalData;
  } catch (e) {
    onError && onError(e);
    return finalData;
  }
}

export async function prepareBreakdowns(organization_id, set_id, onError) {
  try {
    return await getSetBreakdowns(organization_id, set_id, [], 0, onError);
  } catch (e) {
    console.error("error : ", e);
    return null;
  }
}

export async function handleSaveBreakdowns(
  page,
  organization_id,
  set_id,
  breakdowns,
  oldBreakdowns,
  updateIds,
  success
) {
  let newBreakdowns = breakdowns;

  const flatBreakdowns = flattenBreakdowns(breakdowns);
  const flatOldBreakdowns = flattenBreakdowns(oldBreakdowns);

  const breakdown_order = flatBreakdowns.map((br) => br.id);
  const flatBreakdownsIds = new Set(breakdown_order);
  const flatOldBreakdownsIds = new Set(flatOldBreakdowns.map((br) => br.id));

  for (const id of updateIds) {
    if (!flatOldBreakdownsIds.has(id) && flatBreakdownsIds.has(id)) {
      const breakdown = flatBreakdowns.find((br) => br.id === id);
      const breakdownApi = getApiBreakdown(breakdown, set_id, organization_id);
      delete breakdownApi.id;

      try {
        const res = await breakdownsApi.addBreakdown(breakdownApi);
        newBreakdowns = updateBreakdownApiId(
          newBreakdowns,
          breakdown.id,
          res.id
        );
      } catch (e) {
        console.error("error : ", e);
      }
    } else if (flatOldBreakdownsIds.has(id) && flatBreakdownsIds.has(id)) {
      const updatedBreakdown = flatBreakdowns.find((br) => br.id === id);
      const updatedBreakdownApi = getApiBreakdown(
        updatedBreakdown,
        set_id,
        organization_id
      );

      try {
        await breakdownsApi.updateBreakdownById(updatedBreakdownApi);
      } catch (e) {
        console.error("error : ", e);
      }
    } else {
      const deleteBreakdown = flatOldBreakdowns.find((br) => br.id === id);

      if (deleteBreakdown) {
        const deleteBreakdownApi = getApiBreakdown(
          deleteBreakdown,
          set_id,
          organization_id
        );
        try {
          await breakdownsApi.removeBreakdownById(deleteBreakdownApi.id);
        } catch (e) {
          console.error("error : ", e);
        }
      }
    }
  }

  await saveViewMetadata(page.id, {
    ...page.metadata,
    breakdown_order,
  });

  success && success(newBreakdowns, breakdown_order);
}

export async function searchClassification(setId, viewId, classificationId) {
  try {
    const res = await setApi.searchClassification(setId, classificationId);
    if (res?.length > 0) {
      return res.filter((id) => id !== viewId);
    }
    return [];
  } catch (e) {
    console.error("error : ", e);
    return [];
  }
}
