import { RgbaRepresentation, RgbRepresentation } from "../types/editor";
import { addOpacity, hexToRgb } from "../utils/style";

const FONT_SIZES = {
  FONT_SMALL: "12px",
  FONT_DEFAULT: "14px",
  FONT_HEADER: "16px",
  FONT_BIG: "18px",
  FONT_MEDIUM_HEADER: "20px",
  FONT_EXBIG: "24px",
  FONT_EXBIG2: "30px",
  FONT_TITLE: "38px",
} as const;

const LINE_HEIGHTS = {
  LINE_HEIGHT_SMALL: "14px",
  LINE_HEIGHT_SMALL_2: "16px",
  LINE_HEIGHT_SMALL_3: "18px",
  LINE_HEIGHT_MEDIUM: "20px",
  LINE_HEIGHT_MEDIUM_2: "22px",
  LINE_HEIGHT_EXBIG: "28px",
  LINE_HEIGHT_TITLE: "46px",
} as const;

export const FONT_WEIGHTS = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  SEMIBOLD: "600",
  BOLD: "700",
  EXTRABOLD: "900",
} as const;

const COLORS = {
  PINK: "#FCC7C3",
  BACKGROUND: "#ffffff",
  ALMOST_WHITE: "#fcfcfd",
  GREY: "#F2F2F7",
  BORDER_COLOR: "#E3E4E8",
  DARK_BORDER_COLOR: "#454e57",
  DISABLEDGREY: "#BDBFC1",
  LABELGREY: "#D5D8DD",
  ACTIVEGREY: "#F4F5F7",
  PROGRESSGREY: "#e7e8eb",
  MIDGREY: "#E3E4E8",
  DARKGREY: "#1C2931",
  BLACK: "#000000",
  WHITE: "#ffffff",
  ERROR: "#F44336",
  ORANGE5: "#FF9800",
  PROFILE: "#F71560",
  BLUE: "#1E9FE8",
  DARKBLUE: "#0D5F8B",
  WARNING: "#FE7A05",
  GREEN: "#3db842",
  MAINCOLOR: "#3DB842",
  TOGALCHAT: "#009568",
  MAINCOLORLIGHT: "#D8F9DA",
  BORDER_DARK: "#d0d3d9",
  SHORTCUT_DARK: "rgba(9, 15, 19, 1)",
  GRAY1: "#F9F9FA",
  GRAY2: "#F0F1F3",
  GRAY3: "#E6E6E6",
  GRAY4: "#DDDFE3",
  GRAY5: "#DCDCDC",
  GRAY6: "#61696F",
  GRAY7: "#323232",
  GRAY8: "#1C2931",
  HEADERGRAY: "#49545A",
  MULTIPLIER: "#3F51B5",
  PURPLE: "#9747FF",
  PURPLE2: "#8C2E89",
  DARKPURPLE: "#46059c",
  MAIN1: "#EFFDF0",
  MAIN2: "#C5EAC6",
  MAIN3: "#1BD6BF",
  CHECKBOXCOLOR: "#11191F",
  UOM_GRAY: addOpacity("#000000", 46),
} as const;

const MODAL_SIZES = {
  MODAL_S: "300px",
  MODAL_M: "500px",
  MODAL_L: "800px",
  MODAL_LM: "900px",
  MODAL_XL: "1140px",
} as const;

const REMAINING_PROPERTIES = {
  BORDER_RADIUS: "2px",
  BORDER_RADIUS2: "4px",
  BORDER_RADIUS3: "6px",
  BORDER_WIDTH: "1px",
  FONT_FAMILY: "Proxima Nova, sans serif",
  CONTEXT_MENU_SIDE_PADDING: "16",

  TRANSPARENT: "transparent",
  PADDING: "10px",
  BOX_SHADOW_PLACEMENT: "0px 4px 24px",
  BOX_SHADOW: "0px 4px 24px rgba(124, 147, 156, 0.25)",
  BOX_SHADOW2: "0px 4px 12px rgba(124, 147, 156, 0.25)",
  BOX_SHADOW3: "0px 8px 12px rgba(124, 147, 156, 0.25)",
  AUTO: "auto",
  NONE: "none",
  Z_INDEX_MAX: "100000",
} as const;

type CSSType = typeof COLORS &
  typeof MODAL_SIZES &
  typeof FONT_SIZES &
  typeof FONT_WEIGHTS &
  typeof LINE_HEIGHTS &
  typeof REMAINING_PROPERTIES;

export const CSS: CSSType = {
  ...COLORS,
  ...MODAL_SIZES,
  ...FONT_SIZES,
  ...FONT_WEIGHTS,
  ...LINE_HEIGHTS,
  ...REMAINING_PROPERTIES,
};

export const RGB_COLORS: Record<string, RgbRepresentation> = {
  BLACK: [0, 0, 0],
  RED: [255, 0, 0],
  GREEN: [61, 184, 66],
  JOINT: [232, 245, 253],
  WHITE: [255, 255, 255],
  SHADOW: [124, 147, 156],
  PIPELINE: [30, 159, 232],
  DARKJOINT: [30, 74, 232],
  DRAIN_EXIT: [186, 130, 184],
  DRAIN_COMMODE: [253, 98, 35],
  DRAIN_OTHER_FIXTURE: [75, 144, 255],
  PURPLE: hexToRgb(CSS.PURPLE),
  MAINCOLOR: hexToRgb(CSS.MAINCOLOR),
  DARKPURPLE: hexToRgb(CSS.DARKPURPLE),
};

export const RGBA_COLORS: Record<string, RgbaRepresentation> = {
  DEFAULT: [20, 20, 200, 255],
  RED: [...RGB_COLORS.RED, 255],
  BLACK: [...RGB_COLORS.BLACK, 255],
  JOINT: [...RGB_COLORS.JOINT, 255],
  WHITE: [...RGB_COLORS.WHITE, 255],
  PURPLE: [...RGB_COLORS.PURPLE, 255],
  TRANSPARENT: [...RGB_COLORS.BLACK, 0],
  PIPELINE: [...RGB_COLORS.PIPELINE, 255],
  DARKPURPLE: [...RGB_COLORS.DARKPURPLE, 255],
  DARKJOINT: [...RGB_COLORS.DARKJOINT, 255],
  DRAIN_EXIT: [...RGB_COLORS.DRAIN_EXIT, 255],
  DRAIN_COMMODE: [...RGB_COLORS.DRAIN_COMMODE, 255],
  DRAIN_OTHER_FIXTURE: [...RGB_COLORS.DRAIN_OTHER_FIXTURE, 255],
};
