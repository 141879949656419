import React, { useRef, useState, useContext, createContext } from "react";

import { useRafLoop } from "sf/hooks";
import { prop } from "sf/utils/functional/object";

import { getUser } from "../../modules/Auth/context";
import { useDataStore } from "src/UtilComponents/DataManager";
import {
  uploadPage,
  processFiles,
  createPageView,
  updatePageState,
  createPageRecord,
} from "./utils";

const UPLOAD_BATCH_SIZE = 10;
const UploadContext = createContext();

export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const user = getUser();
  const { syncPage } = useDataStore();

  const lastTime = useRef(0);
  const newFiles = useRef([]);
  const selectedSetId = useRef();
  const removeFiles = useRef([]);
  const tempPagesState = useRef(new Map());
  const shouldCancelRef = useRef(false);
  const tempStateUpdated = useRef(false);
  const processingFiles = useRef(new Set());

  const [isProcessing, setIsProcessing] = useState(false);

  const [uploads, setUploads] = useState(new Map());
  const [pagesState, setPagesState] = useState(new Map());
  const [removedPages, setRemovedPages] = useState(new Map());
  const [uploadFilesLength, setUploadFilesLength] = useState(0);

  function updateActiveSet(newSetId) {
    selectedSetId.current = newSetId;
  }

  function updatePagesState(newState) {
    setPagesState(new Map(newState));
  }

  useRafLoop(
    (time) => {
      const lastStep = lastTime.current;
      const targetFPS = 300;
      const step = time - lastStep;
      if (step >= targetFPS) {
        if (tempStateUpdated.current) {
          tempStateUpdated.current = false;
          updatePagesState(tempPagesState.current);
        }
        lastTime.current = time;
        if (processingFiles.current.size === 0 && isProcessing) {
          setIsProcessing(false);
        } else if (processingFiles.current.size > 0 && !isProcessing) {
          setIsProcessing(true);
        }

        if (newFiles.current.length > 0 || removeFiles.current.length > 0) {
          let filesCopy = [...newFiles.current];
          const removeFilesCopy = [...removeFiles.current];
          const pagesStateCopy = new Map(pagesState);
          newFiles.current = [];
          removeFiles.current = [];

          const uploadsCopy = new Map(uploads);

          if (removeFilesCopy.length > 0) {
            filesCopy = filesCopy.filter(
              (f) => !removeFilesCopy.includes(f.id)
            );
            pagesStateCopy.forEach((page) => {
              if (removeFilesCopy.includes(page.id)) {
                pagesStateCopy.delete(page.pageId);
              }
            });
            updatePagesState(pagesStateCopy);

            removeFilesCopy.forEach((fileId) => {
              if (uploadsCopy.has(fileId)) {
                uploadsCopy.delete(fileId);
              }
            });
          }

          if (filesCopy.length > 0) {
            filesCopy.forEach((file) => {
              if (!uploadsCopy.has(file.id)) {
                uploadsCopy.set(file.id, file);
              }
            });
          }

          setUploads(new Map(uploadsCopy));
        }
      }
    },
    true,
    [
      uploads,
      newFiles,
      pagesState,
      removeFiles,
      isProcessing,
      tempPagesState,
      tempStateUpdated,
    ]
  );

  function addFile(file) {
    newFiles.current.push(file);
  }

  function removeFile(id) {
    removeFiles.current.push(id);
  }

  function cancelAllUploads() {
    shouldCancelRef.current = true;
  }

  async function handleUploadPage(
    pages,
    index,
    setId,
    projectId,
    folderId,
    uploadsCopy,
    filteredUploads,
    showError
  ) {
    function setStateInternal(page) {
      tempPagesState.current.set(page.pageId, page);
      tempStateUpdated.current = true;
    }

    if (shouldCancelRef.current) {
      setIsProcessing(false);

      Array.from(tempPagesState.current.values()).forEach((page) => {
        setStateInternal({ ...page, isUploading: false });
      });
    } else {
      // -------------------------------------------- //
      // -------------------------------------------- //
      //MAIN UPLOAD LOGIC
      // -------------------------------------------- //
      // -------------------------------------------- //
      let page = pages[index];

      if (!page.apiPage && !page.error) {
        page = await createPageRecord(
          projectId,
          setId,
          folderId,
          page,
          showError
        );
        setStateInternal(page);
      }

      if (page.apiPage && page.apiPage.id && !page.error && !page.isUploaded) {
        page = await uploadPage(page, (_, updatedPage) => {
          setStateInternal(updatedPage);
        });
        setStateInternal(page);
      }

      if (
        page?.apiPage &&
        page?.apiPage.id &&
        page?.isUploaded &&
        !page?.apiPage?.active
      ) {
        page = await updatePageState(page, folderId, showError);
        setStateInternal(page);
      }

      if (
        page.apiPage &&
        page.apiPage.id &&
        page.isUploaded &&
        page.apiPage.active
      ) {
        page = await createPageView(
          setId,
          page,
          user?.name,
          showError,
          syncPage
        );
        setStateInternal(page);
      }

      setStateInternal(page);

      filteredUploads.forEach(([, file]) => {
        const filterFilePages = [...tempPagesState.current]
          .map(([, p]) => p)
          .filter((p) => p.id === file.id);

        if (filterFilePages.every(prop("isUploaded"))) {
          removeFile(file.id);
        }
      });

      // -------------------------------------------- //
      // -------------------------------------------- //
      //MAIN UPLOAD LOGIC
      // -------------------------------------------- //
      // -------------------------------------------- //

      if (index >= pages.length - 1) {
        setIsProcessing(false);

        for (const [, file] of filteredUploads) {
          if (!file.error && !uploadsCopy.get(file.id)?.error) {
            removeFile(file.id);
          }
        }
      }

      return tempPagesState.current;
    }
  }

  async function startUpload(
    filteredUploads,
    projectId,
    setId,
    folderId,
    showError
  ) {
    shouldCancelRef.current = false;

    processingFiles.current.add(setId);
    console.time("processing step");
    const processed = await processFiles(
      filteredUploads,
      uploads,
      pagesState,
      removedPages
    );
    const uploadsCopy = new Map(processed.uploads);
    const pagesStateCopy = new Map(processed.pagesState);
    tempPagesState.current = new Map(pagesStateCopy);
    tempStateUpdated.current = true;

    const pagesStateLength = pagesStateCopy?.size || 0;
    setUploads(new Map(uploadsCopy));
    setUploadFilesLength(pagesStateLength);

    console.timeEnd("processing step");
    processingFiles.current.delete(setId);

    const filteredIds = filteredUploads.map(
      (filteredUpload) => filteredUpload[1].id
    );

    const pages = [...pagesStateCopy]
      .map((page) => page[1])
      .filter((page) => filteredIds.includes(page.id));

    let index = 0;

    while (index < pages.length) {
      const batchIndexes = [];

      for (let i = 0; i < UPLOAD_BATCH_SIZE && index < pages.length; i++) {
        batchIndexes.push(index);
        index += 1;
      }

      const uploadPromises = batchIndexes.map((i) =>
        handleUploadPage(
          pages,
          i,
          setId,
          projectId,
          folderId,
          uploadsCopy,
          filteredUploads,
          showError
        )
      );

      await Promise.all(uploadPromises);
    }
  }

  return (
    <UploadContext.Provider
      value={{
        uploads,
        pagesState,
        isProcessing,
        removedPages,
        addFile,
        removeFile,
        startUpload,
        updateActiveSet,
        setRemovedPages,
        cancelAllUploads,
        uploadFilesLength,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};
