import React from "react";

import { addOpacity } from "sf/utils/style";
import { Para } from "sf/components/Styled";
import { FONT_WEIGHTS } from "sf/consts/style";
import AreaIcon from "sf/components/Icons/AreaIcon";
import { CountPara } from "sf/components/Legend/styles";
import { UnitPara } from "src/modules/Editor/QuantityList/DefaultQuantityList/ListItem/styles";

import {
  ExpandButton,
  StyledChevron,
  ClassificationContainer,
} from "../styles";
import Icon from "sf/components/Icon";
import { QP_ICONS_IDS } from "sf/components/Icon/quantity_panel_icons";

function Classification() {
  return (
    <ClassificationContainer>
      <ExpandButton value={<StyledChevron />} />
      <Icon id={QP_ICONS_IDS.SELECTED} />
      <AreaIcon color="#FF6107" />
      <Para style={{ marginLeft: "5px" }}>Classification</Para>
      <CountPara
        isBackgroundHighlited={false}
        fontSize={CSS.FONT_DEFAULT}
        fontColor={addOpacity(CSS.BLACK, 46)}
      >
        2
      </CountPara>
      <UnitPara
        fontWeight={FONT_WEIGHTS.REGULAR}
        fontSize={CSS.FONT_DEFAULT}
        fontColor="#838386"
        style={{ position: "absolute", right: "5px" }}
      >
        220 sq ft
      </UnitPara>
    </ClassificationContainer>
  );
}

export default Classification;
